import React from "react";
import { Helmet } from "react-helmet";
import Phone from "../../components/Shared/Phone"
const classes = require('./WineDinner.module.scss');

const wineDinner = () => {
  return (
    <div className={classes.WineDinner}>
      <Helmet>
        <title>Waterfront Wine Dinners at Mile Marker 158 Dockside</title>
        <meta name="description" content="Check out the next wine pairing at Mile Marker 158 Dockside, located at The Wharf in Orange Beach, Alabama." />
      </Helmet>

      <div className={classes.medDef}>
        <div className={classes.menuArea}>
          <div className={classes.inside}>
            <div className={classes.titleRow}>
              <div className={classes.inner}>
                <h1>April Wine Dinner</h1>
                <h2>
                  April 3 <br className={classes.br1}/>
                  <span className={classes.sp1}>&nbsp;|&nbsp;</span> 6pm <span className={classes.sp2}>&nbsp;|&nbsp;</span> $55 Per Guest
                </h2>
                <h4>For Reservations: <Phone where="wineDinner" /></h4>
              </div>
            </div>
          </div>

          <div className={[classes.inside, classes.stip].join(' ')}>
            <div className={classes.menuRow}>
              <div className={classes.menuBoxFull}>
                <h5>First Course</h5>
                <h4>West Indies Salad</h4>
                <p>Crab Meat, Onion, Bell Pepper, Arugula, Champagne Vinaigrette</p>
                <p className={classes.pair}>Paired With</p>
                <h4>Spoken West Chardonnay California 2022</h4>
              </div>

              <div className={classes.menuBoxFull}>
                <h5>Second Course </h5>
                <h4>Crawfish and Sausage Stuffed Shrimp</h4>
                <p>Stuffed Shrimp Over Cheddar Grits, Roasted Red Pepper Sauce</p>
                <p className={classes.pair}>Paired With</p>
                <h4>Noah River Pinot Noir California 2022</h4>
              </div>

              <div className={classes.menuBoxFull}>
                <h5>Third Course</h5>
                <h4>Roasted Pork Tenderloin</h4>
                <p>Pork Tenderloin, Sweet Potato Gratin, Sweet Chili Glazed Brussel Sprouts</p>
                <p className={classes.pair}>Paired With</p>
                <h4>Spoken West Cabernet Sauvignon California 2022</h4>
              </div>

              <div className={classes.menuBoxFull}>
                <h5>Fourth Course</h5>
                <h4>Peach and Blackberry Cobbler</h4>
                <p>Cobbler Served with Amaretto Blackberry Whipped Cream</p>
                <p className={classes.pair}>Paired With</p>
                <h4>Los Monteros Cava Brut Spain NV</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default wineDinner;